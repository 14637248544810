@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: #7800ff;
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-authenticator-modal-background-color: #dddddd;
  --amplify-components-tabs-item-active-border-color: #7800ff;
  --amplify-components-tabs-item-active-color: #7800ff;
  --amplify-components-button-primary-background-color: #7800ff;

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-MainLogo {
  height: 15vmin;
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-MainLogo {
    animation: zoom-in-zoom-out infinite 3s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoL2R {
    animation: Logo-spinL2R infinite 5s ease-in-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoR2L {
    animation: Logo-spinR2L infinite 5s ease-in-out;
  }
} */
.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

@keyframes Logo-spinL2R {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Logo-spinR2L {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #0099ff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.banner_logo {
  width: 25vmin;
  position: absolute;
  top: 5vmin;
  right: 10vmin;
}
.dropdown_select {
  text-align: start;

}
.contact_item {
  border: 1px solid #282c34;
  border-radius: 1.5vmin;
  padding: 2vmin;
  margin: 1vmin;
}
.section_heading {
  margin-bottom: 2vmin;
}
.contacts_details {
  margin: 1vmin;
  max-height: 50vmin;
  overflow-y:auto
}
.close_button {
  float: right;
  cursor: pointer;
}
.add_button {
  color: #7800ff;
  cursor: pointer;
}
.drag_drop_area {  
  text-align: center;
  padding: 5vmin;
  border: 3px #767676 dashed;
  margin: auto;
}
.area__icon {
  font-size: 64px;
  margin-top: 20px;
}
.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: #0099ff;
  color: #ffffff;
}
.file_list {
  list-style-type: none;
  font-size: small;
  width: 100%;
  align-items: center;
}
.uploadfiles_list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 15vmin;
}
.s3_fileuploader_container {
  padding: 1vmin;
  border: 1px solid #7800ff;
  min-height: 3vmin;
  font-size: 1.5vmin;
  text-align: start;
}
.s3_fileuploader_progressbar {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: auto;
}
.s3_fileuploader_progressbar_completed {
  color: #00CC00;
  font-size: 2vmin;
}
.summary_section {
  margin: 1vmin;
  padding: 1vmin;
  border: 1px solid #7800ff;
  border-radius: 1.5vmin;
}
.file_list_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5vmin;
}